import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require('@/utils/CustomUtil')
import { getParentData } from '@/utils/index.js'

import ElementUI from 'element-ui'
import vueEsign from 'vue-esign'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '@/assets/element-style/theme/index.css'
import '@/assets/theme.less'
import '@/assets/global.css'
import '@/assets/iconfont/iconfont.css'

// 图片预览插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import * as echarts from 'echarts'
Vue.prototype.echarts = echarts

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
console.log('store', store)

import api from './api/index.js'
import * as utils from './utils/index.js'
import * as format from './utils/format.js'
import * as constant from './utils/constant.js'
import CommonDirectives from './utils/directives.js'
import CommonFilters from './utils/filter.js'
import CommonComponents from './components/index.js'
// 引入自定义全局组件
CommonComponents.forEach(components => Vue.use(components))

Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$format = format
Vue.prototype.$constant = constant

Vue.use(ElementUI)
Vue.use(vueEsign)
Vue.use(CommonFilters)
Vue.use(CommonDirectives)

Vue.config.productionTip = false

Vue.prototype.$isNotEmpty = function (obj) {
  return obj !== undefined && obj !== null && obj !== '' && obj !== 'null'
}

Vue.prototype.$getDefalut = function (obj, key, df) {
  return obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])
    ? df
    : obj[key]
}

Vue.prototype.$deepCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}

// 图片代理
Vue.prototype.interceptImg = src => {
  if (!src) return ''

  if (src.includes(process.env.VUE_APP_IMAGE_URL)) {
    return src
  } else {
    return process.env.VUE_APP_IMAGE_URL + src
  }
}

window.onload = function () {
  window.addEventListener('message', function ({ data }) {
    // console.log('父页面与子页面交互main.js')
    // console.log(data)
    // store.commit('templateData', { name: 4444444 })
    // iframeReceiveData(e.data)
    switch (data.type) {
      //接受父页面传递的数据
      case 'passGinseng':
        store.commit('updateTemplateData', data.data)
        break
    }
  })
}

NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
  template:
    '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"></div>'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
