<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        ></vue-cropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-zoom-in"
            @click="changeScale(1)"
          >
            放大
          </el-button>
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-zoom-out"
            @click="changeScale(-1)"
          >
            缩小
          </el-button>
          <el-button size="mini" type="danger" plain @click="rotateLeft">
            ↺ 左旋转
          </el-button>
          <el-button size="mini" type="danger" plain @click="rotateRight">
            ↻ 右旋转
          </el-button>
        </div>
        <div>
          <el-button size="mini" @click="cancel">取消</el-button>
          <el-button size="mini" type="success" @click="uploadImg('blob')">
            确定
          </el-button>
        </div>
      </div>
    </div>
    <!--预览效果图-->
    <div class="show-preview">
      <div class="preview" :style="previews.div">
        <img :src="previews.url" width="300px" :style="previews.img" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  name: 'CropperImage',
  props: {
    base64: {
      type: String,
      require: true
    },
    boxWidth: {
      type: Number,
      default: 200
    },
    boxHeight: {
      type: Number,
      default: 200
    },
    sizeChange: {
      default: true
    }
  },
  components: {
    VueCropper
  },
  data() {
    return {
      previews: {},
      option: {
        img: '', //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg', //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: this.boxWidth, //默认生成截图框宽度
        autoCropHeight: this.boxHeight, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [this.boxWidth, this.boxHeight], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: false, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1 //图片根据截图框输出比例倍数
      }
    }
  },
  created() {
    if (this.base64) {
      this.option.img = this.base64
    }
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      console.log('工具初始化函数=====' + msg)
    },
    //图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    //实时预览函数
    realTime(data) {
      this.previews = data
    },

    //上传图片
    uploadImg() {
      //获取截图的blob数据
      this.$refs.cropper.getCropBlob(data => {
        this.$emit('cropSuccess', data)
      })
      // // //获取截图的base64数据
      // this.$refs.cropper.getCropData(data => {
      //   this.$emit('cropSuccess', data)
      // })
    },
    cancel() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="less">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 500px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }
}
</style>
