<template>
  <div :class="`page ${tableNeedFixed && 'pageFix'}`">
    <div class="page-title" v-if="showTitle">{{ routerTitle }}</div>
    <div v-if="$slots.tabs" class="tabs">
      <slot name="tabs" ref="tabs"></slot>
    </div>
    <div
      v-if="$slots.search"
      class="search"
      ref="selectWrap"
      :style="{
        maxHeight: !showMoreFilter || isShow ? 'inherit' : '51px'
      }"
    >
      <div class="select-wrap">
        <slot name="search" ref="search"></slot>
      </div>
      <div class="search-btn" style="white-space: nowrap">
        <template v-if="showMoreFilter">
          <el-button type="text" @click="isShow = !isShow">
            <i :class="!isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            {{ !isShow ? '展开' : '收缩' }}
          </el-button>
        </template>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="parentGetData"
        ></el-button>
        <el-button type="" @click="parentClear">重置</el-button>
        <slot name="otherBtn" ref="otherBtn"></slot>
        <!-- <el-button type="primary" plain @click="parentGetData">查询</el-button> -->
      </div>
    </div>
    <div v-if="$slots.auditHeader">
      <slot name="auditHeader" ref="auditHeader"></slot>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
    <div class="pagination" v-if="$slots.pagination">
      <slot name="pagination"></slot>
    </div>
    <div class="bottom" v-if="$slots.bottom || showReturn">
      <slot name="bottom"></slot>
      <el-button type="primary" @click="returnEvent" v-if="showReturn">
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showReturn: {
      type: Boolean,
      default: false
    },
    showMoreFilter: {
      type: Boolean,
      default: false
    },
    tableNeedFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    routerTitle() {
      return this.$route.meta.title
    }
  },
  data() {
    return {
      height: 0,
      isShow: true
    }
  },
  watch: {
    showMoreFilter(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.$nextTick(() => {
          this.height =
            this.$refs.selectWrap && this.$refs.selectWrap.offsetHeight
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.selectWrap && this.$refs.selectWrap.offsetHeight
    })
  },
  methods: {
    parentGetData() {
      this.$parent.replaceData
        ? this.$parent.replaceData()
        : this.$parent.getData(true)
    },
    parentClear() {
      const filtersForm = this.$parent.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.$parent.clearParams &&
        this.$parent.clearParams(
          filtersForm ? filtersForm.$data.fields : undefined
        )
      this.$nextTick(() => {
        !this.$parent.replaceData ? this.$parent.getData(true) : false
      })
    },
    returnEvent() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  &.pageFix {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 30px);
    box-sizing: border-box;
    .page-content {
      flex: 1;
      display: flex;
      height: 0;
      flex-direction: column;
      overflow: auto;
      /deep/ .table-wrap {
        position: relative;
        flex: 1;
        .el-table {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
.page-title {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f6f6f6;
  font-size: 14px;
}
.page-content {
  padding-top: 15px;
}
.search {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  // align-items: center;
  // justify-content: space-between;
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .search-btn {
    margin-left: 20px;
  }
}
.bottom {
  position: fixed;
  bottom: 25px;
  right: 50px;
  z-index: 100;
}
</style>
