//选择序列号
<template>
  <div>
    <div v-if="params.bserial">
      <el-select
        v-model="v"
        reserve-keyword
        filterable
        placeholder="请选择"
        @change="changeEvent"
        style="width: 100%"
        v-bind="$attrs"
        @click.native="checkParams"
        clearable
        :multiple="multiple"
      >
        <el-option v-for="a in list" :key="a" :value="a" :label="a"></el-option>
      </el-select>
    </div>
    <div v-else>
      <el-input
        v-model="v"
        placeholder="请输入"
        @input="inputValue"
        :disabled="inputDisabled"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSn',
  props: {
    params: {
      type: Object,
      default: () => {
        return { cInvCode: '', cWhCode: '', cPosCode: '', bserial: true }
      }
    },
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    inputDisabled: {
      type: Boolean,
      default: false
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (
          newV?.cInvCode != oldV?.cInvCode ||
          newV?.cWhCode != oldV?.cWhCode ||
          newV?.cPosCode != oldV?.cPosCode ||
          newV?.bserial != oldV?.bserial
        ) {
          this.getData()
        }
        //多选初始化
        if (
          this.multiple &&
          this.code &&
          newV?.bserial != oldV?.bserial &&
          newV?.bserial
        ) {
          this.v = this.code.split(',')
          this.changeEvent(this.v)
        }
      }
    },
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (this.multiple && this.params.bserial) {
          this.v = newV ? newV.split(',') : []
        } else {
          this.v = newV
        }
      }
    }
  },
  methods: {
    async getData() {
      try {
        if (!this.params.bserial) return
        const { type } = this
        const { cInvCode, cWhCode, cPosCode } = this.params
        if (cInvCode && cWhCode) {
          this.list = await this.$api.u8.getListSnByInvPosition({
            cInvCode,
            cWhCode,
            cPosCode
          })
        }

        if (!this.list?.length) {
          this.changeEvent('')
        }
        if (this.code && this.list && this.list.length && !this.multiple) {
          let a = this.list.find(_ => _ === this.code)
          if (!a) {
            this.changeEvent('')
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(v) {
      let d = this.multiple ? (v || []).join(',') : v
      this.$emit('update:name', d)
      this.$emit('update:code', d)
      this.$emit('change', this.multiple ? v || [] : v)
    },
    inputValue(value) {
      this.v = value.replace(/[\u4e00-\u9fa5|\s]/g, '')
      this.$emit('update:name', this.v)
      this.$emit('update:code', this.v)
    },
    checkParams(isVisible) {
      if (isVisible && (!this.params.cInvCode || !this.params.cWhCode)) {
        this.$alert('请先选择料号和仓库', '提示', {
          confirmButtonText: '确定',
          callback: action => {}
        })
        return false
      }
    }
  }
}
</script>
