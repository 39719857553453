import request from '@/api/request.js'
import * as utils from '@/utils/index'
import qs from 'qs'

//我的申请
export function getNotifyList(params) {
  return request({
    url: `/notify`,
    method: 'get',
    params
  })
}
// 获取未读
export function getUnreadNum() {
  return request({
    url: `/notify/unreadable`,
    method: 'get'
  })
}
// 操作已读
export function readData(params) {
  return request({
    url: `/notify`,
    method: 'put',
    params
  })
}
// 操作已读
export function readDataByOrderCode(params) {
  return request({
    url: `/notify`,
    method: 'post',
    params
  })
}
// 我的抄送
export function getMyCCList(params) {
  return request({
    url: `/applyAndAudit/cc`,
    method: 'get',
    params
  })
}

/* 
导出接口
故障统计分析：/workOrder/exportFaultStatistic?startTime=2023-09-22 00:00:00&endTime=2023-09-22 23:59:59
区域工单完成情况：/workOrder/exportRegionWorkOrder?startTime=2023-09-22 00:00:00&endTime=2023-09-22 23:59:59
超时工单详情：/workOrder/exportTimeOutDetail?startTime=2023-10-12 15:09:00&endTime=2023-10-12 15:09:00
故障汇总：/workOrder/exportTotalFault?startTime=2022-05-01 00:00:00&endTime=2023-10-12 23:59:59
故障设备详情：/workOrder/exportFaultDetail?startTime=2022-05-01 00:00:00&endTime=2023-10-12 23:59:59
*/
export function export1(params) {
  return request({
    url: `/workOrder/exportFaultStatistic`,
    method: 'get',
    params
  })
}
export function export2(params) {
  return request({
    url: `/workOrder/exportRegionWorkOrder`,
    method: 'get',
    params
  })
}
export function export3(params) {
  return request({
    url: `/workOrder/exportTimeOutDetail`,
    method: 'get',
    params
  })
}
export function export4(params) {
  return request({
    url: `/workOrder/exportTotalFault`,
    method: 'get',
    params
  })
}
export function export5(params) {
  return request({
    url: `/workOrder/exportFaultDetail`,
    method: 'get',
    params
  })
}

// 下载中心 列表
export function getFileList(params) {
  return request({
    url: `/file/list`,
    method: 'get',
    params
  })
}

// 工单项目 列表
export function getProjectList(params) {
  return request({
    url: `/workOrder/project/page`,
    method: 'get',
    params
  })
}
export function getProjectList2(params) {
  return new Promise((resolve, reject) => {
    return request({
      url: `/workOrder/project/page`,
      method: 'get',
      params
    })
      .then(data => {
        resolve(data.list || [])
      })
      .catch(e => {
        console.log(e)
        reject()
      })
  })
}
// 新增项目
export function addProject(data) {
  return request({
    url: `/workOrder/project`,
    method: 'post',
    data
  })
}
// 修改项目
export function editProject(data) {
  return request({
    url: `/workOrder/project`,
    method: 'put',
    data
  })
}
// 删除项目
export function delProject(data) {
  return request({
    url: `/workOrder/project`,
    method: 'delete',
    data
  })
}

// 工单模版 列表
export function getTemplateList(params) {
  return request({
    url: `/workOrder/template/page`,
    method: 'get',
    params
  })
}
// 新增模版
export function addTemplate(data) {
  return request({
    url: `/workOrder/template`,
    method: 'post',
    data
  })
}
// 修改模版
export function editTemplate(data) {
  return request({
    url: `/workOrder/template`,
    method: 'put',
    data
  })
}
// 删除模版
export function delTemplate(data) {
  return request({
    url: `/workOrder/template`,
    method: 'delete',
    data
  })
}
// 配件数据管理列表
export function listFittingData(params) {
  return request({
    url: `/fs/fittingData/page`,
    method: 'get',
    params
  })
}
// 配件数据管理--新增
export function insertFittingData(data) {
  return request({
    url: `/fs/fittingData/save`,
    method: 'post',
    data
  })
}
// 配件数据管理--更新
export function updateFittingData(data) {
  return request({
    url: `/fs/fittingData/update`,
    method: 'put',
    data
  })
}
// 配件数据管理--解绑
export function unbindFittingData(params) {
  return request({
    url: `/fs/fittingData/unbind`,
    method: 'put',
    params
  })
}
// 配件数据管理--删除
export function deleteFittingData(params) {
  return request({
    url: `/fs/fittingData/delete`,
    method: 'delete',
    params
  })
}
// 配件数据管理--导入
export function importFittingData(data) {
  return request({
    url: `/fs/fittingData/import`,
    method: 'post',
    data
  })
}
// 配件数据管理--导出
export function exportFittingData(params) {
  return request({
    url: `/fs/fittingData/export`,
    method: 'get',
    params
  })
}

//查询分公司
export function getBranchOfficeList(params) {
  return request({
    url: `/customer/headOffice/queryBranchOfficeNameByKey`,
    method: 'post',
    params
  })
}
// 查询门店
export function getStoreNameList(params) {
  return request({
    url: `/customer/headOffice/queryStoreNameByKey`,
    method: 'post',
    params
  })
}
//根据门店id 获取 门店编码，联系人，联系人电话，门店地址，省市区
export function getInfoByStoreId(params) {
  return request({
    url: `/workOrder/queryStore1`,
    method: 'post',
    params
  })
}

// 查询报障人
export function getUserList(params) {
  return request({
    url: `/power/user/reportingUserList`,
    method: 'post',
    params
  })
}
// 查询保障类型
export function getFaultList(params) {
  return request({
    url: `/workOrder/queryfaultNameByKey`,
    method: 'post',
    params
  })
}
// 查询序列号
export function getFianSn(params) {
  return new Promise((resolve, reject) => {
    return request({
      url: `/asset/data/fianSnByKey`,
      method: 'post',
      params
    })
      .then(data => {
        let list = (data || []).map(id => {
          return { id }
        })
        resolve(list)
      })
      .catch(e => {
        console.log(e)
        reject()
      })
  })
}

// 批量创建工单
export function orderListAdd(data) {
  return request({
    url: `/workOrder/orderListAdd`,
    method: 'post',
    data
  })
}

// 获取保障工单号
export function listWorkOrderNo(params) {
  return request({
    url: `/workOrder/listWorkOrderNo`,
    method: 'get',
    params
  })
}

// 获取保障工单信息
export function getWorkOrder(params) {
  return request({
    url: `/workOrder/getWorkOrder`,
    method: 'get',
    params
  })
}

// 获取配件类型列表
export function getPartsTypeList(params) {
  return request({
    url: `/partsType/list`,
    method: 'get',
    params
  })
}

// 获取配件类型分页
export function listPartsType(params) {
  return request({
    url: `/partsType/page`,
    method: 'get',
    params
  })
}

// 删除配件类型
export function deletePartsType(params) {
  return request({
    url: `/partsType/` + params,
    method: 'delete'
  })
}

// 新增配件类型
export function insertPartsType(data) {
  return request({
    url: `/partsType`,
    method: 'post',
    data
  })
}

// 编辑配件类型
export function updatePartsType(data) {
  return request({
    url: `/partsType`,
    method: 'put',
    data
  })
}
// 产品管理列表分页
export function getProductPage(params) {
  return request({
    url: `/product/page`,
    method: 'get',
    params
  })
}
// 产品管理列表 详情
export function getProductDetail(id) {
  return request({
    url: `/product/${id}`,
    method: 'get'
  })
}
// 产品管理列表 删除
export function delProduct(id) {
  return request({
    url: `/product/${id}`,
    method: 'delete'
  })
}
// 产品管理 新增产品
export function addProduct(data) {
  return request({
    url: `/product`,
    method: 'post',
    data
  })
}
// 产品管理 更新产品
export function updateProduct(data) {
  return request({
    url: `/product`,
    method: 'put',
    data
  })
}
export function exportProduct(params) {
  return new Promise((resolve, reject) => {
    request
      .get('/product/exportProduct', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        console.log('data.data', data)
        utils.downloadFileBlob(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}
export function exportProductDetail(params) {
  return new Promise((resolve, reject) => {
    request
      .get('/product/exportDetail', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        utils.downloadFileBlob(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}
