//选择货位
<template>
  <el-select
    v-model="v"
    reserve-keyword
    filterable
    placeholder="请选择"
    @change="changeEvent"
    style="width: 100%"
    v-bind="$attrs"
  >
    <el-option
      v-for="a in list"
      :key="a.cposCode"
      :value="a.cposCode"
      :label="a.cposName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectGoodsLocation',
  props: {
    params: {
      type: Object,
      default: () => {
        return { inventoryCode: '', warehouseCode: '' }
      }
    },
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    //转出货位会用到该数据
    maxNum: {
      type: Number,
      default: 0
    },
    //out转出货位，in转入货位
    type: {
      type: String,
      default: 'out'
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (
          newV?.inventoryCode != oldV?.inventoryCode ||
          newV?.warehouseCode != oldV?.warehouseCode
        ) {
          this.getData()
        }
      }
    },
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    },
    list: {
      deep: true,
      immediate: true,
      handler(newV, oldV) {
        if (this.code && !this.num) {
          let a = newV.find(_ => _.cposCode === this.code) || {}
          this.$emit('update:maxNum', a.num || 0)
        }

        const { inventoryCode, warehouseCode } = this.params
        // 货位不存在时调接口查询存货数量
        if (!newV.length && inventoryCode && warehouseCode) {
          this.queryInventoryNum({ inventoryCode, warehouseCode })
        }
      }
    }
  },
  methods: {
    async getData() {
      try {
        const { type } = this
        const { inventoryCode, warehouseCode } = this.params
        this.$emit('update:maxNum', 0)
        if (
          (type === 'out' && inventoryCode && warehouseCode) ||
          (type === 'in' && (inventoryCode || warehouseCode))
        ) {
          const list =
            type === 'out'
              ? await this.$api.u8.gePositionList({
                  inventoryCode,
                  warehouseCode
                })
              : await this.$api.u8.geInPositionList({
                  warehouseCode
                })
          this.list = list
        } else {
          this.list = []
        }
        if (!this.list?.length) {
          this.changeEvent('')
        }
        if (this.code && this.list && this.list.length) {
          let a = this.list.find(_ => _.cposCode === this.code)
          if (!a) {
            this.changeEvent('')
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.cposCode === code) || {}
      this.$emit('update:name', a.cposName || '')
      this.$emit('update:maxNum', a.num || 0)
      this.$emit('update:code', code)
      this.$emit('change', a)
    },
    async queryInventoryNum(params) {
      try {
        let data = await this.$api.u8.queryInventoryNum(params)
        this.$emit('update:maxNum', data || 0)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
