//选择部门
<template>
  <el-select
    v-model="v"
    @change="changeEvent"
    style="width: 100%"
    filterable
    v-bind="$attrs"
  >
    <el-option
      v-for="a in list"
      :key="a.cdepCode"
      :value="a.cdepCode"
      :label="a.cdepName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectDepartment',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    },
    list: {
      deep: true,
      handler(newV, oldV) {
        if (this.code && !this.name) {
          let a = newV.find(_ => _.cdepCode === this.code) || {}
          this.$emit('update:name', a.cdepName)
        }
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const list = await this.$store.dispatch('getDeptListByCode')
        this.list = list
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.cdepCode === code) || {}
      this.$emit('update:name', a.cdepName)
      this.$emit('update:code', code)
      this.$emit('change', a)
    }
  }
}
</script>
