import request from '@/api/request.js'

//报障概况
export function summary(params) {
  return request({
    url: `workOrderStatistics/summary`,
    method: 'get',
    params
  })
}

//客户列表
export function listOffice(params) {
  return request({
    url: `workOrderStatistics/listOffice`,
    method: 'get',
    params
  })
}

//设备型号列表
export function listDevice(params) {
  return request({
    url: `workOrderStatistics/listDevice`,
    method: 'get',
    params
  })
}

//客户报障占比
export function officeWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/officeWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//客户报障趋势
export function officeWorkOrderTrend(params) {
  return request({
    url: `workOrderStatistics/officeWorkOrderTrend`,
    method: 'get',
    params
  })
}

//省份报障分布
export function provinceWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/provinceWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//故障处理方式
export function solveWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/solveWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//故障报障分类
export function faultWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/faultWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//售后配件更换
export function fittingWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/fittingWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//报障设备维修批次
export function fittingNumWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/fittingNumWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//设备型号占比
export function deviceWorkOrderStatistics(params) {
  return request({
    url: `workOrderStatistics/deviceWorkOrderStatistics`,
    method: 'get',
    params
  })
}

//明细表
export function workOrderStatisticsDetails(params) {
  return request({
    url: `workOrderStatistics/details`,
    method: 'get',
    params
  })
}
