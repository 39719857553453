//物品选择输入code搜索
<template>
  <el-select
    v-model="v"
    remote
    reserve-keyword
    filterable
    placeholder="请输入"
    @change="changeEvent"
    :remote-method="
      v => {
        $utils.debounce(remoteMethod, 1000)(v)
      }
    "
    style="width: 100%"
    v-bind="$attrs"
  >
    <el-option
      v-for="a in list"
      :key="a.code"
      :value="a.code"
      :label="a[searchKey]"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectGoods',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    spec: {
      type: String,
      default: ''
    },
    // 查询的键值
    searchKey: {
      type: String,
      default: 'code'
    }
  },
  data() {
    return {
      v: '',
      vName: '',
      list: []
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = this[this.searchKey]
      }
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        let params = { [this.searchKey]: query }
        const { list } = await this.$api.u8.getInventoryList({
          ...params,
          pageNum: 1,
          pageSize: 100
        })
        this.list = list
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.code === code) || {}
      this.$emit('update:name', a.name)
      this.$emit('update:spec', a?.spec || '')
      this.$emit('change', a)
      this.$emit('update:code', code)
    }
  }
}
</script>
