import Vue from 'vue'
import Vuex from 'vuex'
import { transTreeToList } from '../utils/index.js'
import { getMenu, getUserList, getUserInfo } from '../api/common.js'
import {
  getPersonList,
  getDeptListByCode,
  getCustomerList,
  getWarehouseList,
  getRdStyle
} from '../api/u8.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuListTree: [], //菜单按钮树结构
    menuList: [], //菜单按钮
    currentRoute: {}, //当前路由
    personList: [], //申请人列表u8
    userList: [], //申请人列表-工单系统用户
    departmentList: [], //部门列表
    customerList: [], //客户列表
    warehouseList: [], //仓库列表
    warehouseTypeList: [], //出入库类别列表
    templateData: {}, //模版数据
    bomDetails: {}, //bom明细
    flowable: {
      nodeMap: new Map(),
      template: {},
      selectedNode: {},
      runningNodeIds: [],
      waitingNodeIds: [],
      finishedNodeIds: [],
      mode: 'design'
    },
    userInfo: undefined
  },
  mutations: {
    setState(state, { keyName, val }) {
      state[keyName] = val
    },
    selectedNode(state, val) {
      state.flowable.selectedNode = val
    },
    loadTemplate(state, val) {
      state.flowable.template = val
    },
    // 更新使用模板数据
    updateTemplateData(state, val) {
      console.log('55555', val)
      state.templateData = val
    }
  },
  getters: {},
  actions: {
    //获取菜单
    async getMenuAction({ state, commit }) {
      try {
        if (state.menuList.length > 0) return
        let list = await getMenu()
        commit('setState', { keyName: 'menuListTree', val: list })
        list = transTreeToList(list || [], [], { childKey: 'list' })
        commit('setState', { keyName: 'menuList', val: list })
      } catch (err) {
        console.log(err)
      }
    },
    //获取申请人列表u8
    async getPersonList({ state, commit }) {
      try {
        if (state.personList.length > 0) return state.personList
        const list = await getPersonList()
        commit('setState', { keyName: 'personList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //获取申请人列表-工单系统用户
    async getUserList({ state, commit }) {
      try {
        if (state.userList.length > 0) return state.userList
        const list = await getUserList()
        commit('setState', { keyName: 'userList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //获取部门列表
    async getDeptListByCode({ state, commit }) {
      try {
        if (state.departmentList.length > 0) return state.departmentList
        const list = await getDeptListByCode()
        commit('setState', { keyName: 'departmentList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //获取客户列表
    async getCustomerList({ state, commit }) {
      try {
        if (state.customerList.length > 0) return state.customerList
        const list = await getCustomerList()
        commit('setState', { keyName: 'customerList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //获取仓库列表
    async getWarehouseList({ state, commit }, inventoryCode) {
      try {
        // if (state.warehouseList.length > 0) return state.warehouseList
        const list = await getWarehouseList({ inventoryCode })
        commit('setState', { keyName: 'warehouseList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //获取出入库列表 0出库 1入库
    async getRdStyle({ state, commit }, type) {
      try {
        if (state.warehouseTypeList.length > 0) return state.warehouseTypeList
        const list = await getRdStyle({ type })
        commit('setState', { keyName: 'warehouseTypeList', val: list })
        return list
      } catch (err) {
        console.log(err)
      }
    },
    //用户信息
    async getUserInfo({ state, commit }) {
      try {
        if (state.userInfo) return
        let userInfo = await getUserInfo()
        commit('setState', { keyName: 'userInfo', val: userInfo })
      } catch (err) {
        console.log(err)
      }
    }
  },
  modules: {}
})
