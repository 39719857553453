import request from '@/api/request.js'
import * as utils from '@/utils/index'
const baseUrl = 'u8'

//获取人员信息
export function getPersonList(params) {
  return request({
    url: `${baseUrl}/query/getPersonList`,
    method: 'get',
    params
  })
}

//获取部门信息
export function getDeptList(params) {
  return request({
    url: `${baseUrl}/query/getDeptList`,
    method: 'get',
    params
  })
}

//获取全部部门信息
export function getDeptListByCode(params) {
  return request({
    url: `${baseUrl}/query/getDeptListByCode`,
    method: 'get',
    params
  })
}

//获取客户信息
export function getCustomerList(params) {
  return request({
    url: `${baseUrl}/query/getCustomerList`,
    method: 'get',
    params
  })
}

//获取仓库信息
export function getWarehouseList(params) {
  return request({
    url: `${baseUrl}/query/getWarehouseList`,
    method: 'get',
    params
  })
}

//获取存货信息
export function getInventoryList(data) {
  return request({
    url: `${baseUrl}/query/getInventoryList`,
    method: 'post',
    data
  })
}

//获取货位信息 需要传入仓库编码 存货编码
export function gePositionList(params) {
  return request({
    url: `${baseUrl}/query/gePositionList`,
    method: 'get',
    params
  })
}

//获取调入货位信息 需要传入仓库编码
export function geInPositionList(params) {
  return request({
    url: `${baseUrl}/query/geInPositionList`,
    method: 'get',
    params
  })
}

//获取出入库信息 0出库 1入库
export function getRdStyle(params) {
  return request({
    url: `${baseUrl}/query/getRdStyle`,
    method: 'get',
    params
  })
}
// 查询存货
export function queryInventoryNum(params) {
  return request({
    url: `${baseUrl}/query/findCurrentStock`,
    method: 'get',
    params
  })
}
// 货位信息查询
export function queryPageInvPosition(params) {
  return request({
    url: `${baseUrl}/query/pageInvPosition`,
    method: 'get',
    params
  })
}
// 货位序列号分页查询
export function queryListSnByInvPosition(params) {
  return request({
    url: `${baseUrl}/query/pageSnByInvPosition`,
    method: 'get',
    params
  })
}

export function exportInvPosition(params) {
  return new Promise((resolve, reject) => {
    request
      .get(`${baseUrl}/query/exportInvPosition`, {
        params,
        responseType: 'blob'
      })
      .then(data => {
        utils.downloadFileBlob(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 货位序列号查列表询
export function getListSnByInvPosition(params) {
  return request({
    url: `${baseUrl}/query/listSnByInvPosition`,
    method: 'get',
    params
  })
}
