//物品选择输入code搜索
<template>
  <el-select
    v-model.trim="v"
    remote
    reserve-keyword
    filterable
    placeholder="请输入"
    @change="changeEvent"
    :remote-method="
      v => {
        $utils.debounce(remoteMethod, 1000)(v)
      }
    "
    style="width: 100%"
    v-bind="$attrs"
  >
    <el-option
      v-for="a in list"
      :key="a.code"
      :value="a.code"
      :label="a.code"
      :bserial="a.bserial"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectGoodsCode',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    // 是否勾选序列号 true是 false否
    bserial: {
      type: Boolean,
      default: true
    },
    params_bserial: {
      type: Boolean | undefined,
      default: undefined
    }
  },
  data() {
    return {
      v: '',
      vName: '',
      list: []
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
        if (!this.list?.length && newV) {
          this.remoteMethod(newV, () => {
            this.changeEvent(newV)
          })
        }
      }
    }
  },
  methods: {
    async remoteMethod(query, callback) {
      try {
        //空格替换为'''
        let params = { code: query.replace(/\s/g, ''), name: '' }
        const { list } = await this.$api.u8.getInventoryList({
          ...params,
          bserial: this.params_bserial,
          pageNum: 1,
          pageSize: 100
        })
        this.list = list
        typeof callback === 'function' && callback()
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.code === code) || {}
      this.$emit('update:name', a.name)
      this.$emit('update:code', code)
      this.$emit('update:bserial', a.bserial)
      this.$emit('change', a)
    }
  }
}
</script>
