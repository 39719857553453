//选择仓库
<template>
  <el-select v-model="v" @change="changeEvent" style="width: 100%" filterable>
    <el-option
      v-for="a in list"
      :key="a.cwhCode"
      :value="a.cwhCode"
      :label="a.cwhName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectWarehouse',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    inventoryCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      v: '',
	  inventory: this.inventoryCode,
      list: []
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    },
    list: {
      deep: true,
      handler(newV, oldV) {
        if (this.code && !this.name) {
          let a = newV.find(_ => _.cwhCode === this.code) || {}
          this.$emit('update:name', a.cwhName)
        }
      }
    },
    inventoryCode: {
      immediate: true,
      handler(newV, oldV) {
        if (newV) {
		  this.inventory = newV
          this.getList()
        }
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.$emit('update:name', '')
        this.$emit('update:code', '')
        const list = await this.$store.dispatch(
          'getWarehouseList',
          this.inventory
        )
        this.list = list
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.cwhCode === code) || {}
      this.$emit('update:name', a.cwhName)
      this.$emit('update:code', code)
      this.$emit('change', a)
    }
  }
}
</script>
