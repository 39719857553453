<template>
  <!-- 搜索查询搜索 组件 -->
  <el-select
    v-model="v"
    remote
    reserve-keyword
    filterable
    placeholder="请输入搜索"
    :clearable="clearable"
    @change="changeEvent"
    @clear="$emit('clear')"
    @focus="focusEvent"
    :remote-method="
      v => {
        $utils.debounce(remoteMethod, 1000)(v)
      }
    "
    style="width: 100%"
    v-bind="$attrs"
  >
    <el-option
      v-for="a in list"
      :key="a[valueKey]"
      :value="String(a[valueKey])"
      :label="a[labelKey]"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectSearch',
  props: {
    value: {
      type: String,
      default: ''
    },
    //下拉选项 label 显示值
    labelKey: {
      type: String,
      default: 'name'
    },
    // 下拉选项 value 值
    valueKey: {
      type: String,
      default: 'id'
    },
    // 清空按钮
    clearable: {
      type: Boolean,
      default: false
    },
    // 自己传入的搜索函数
    searchFn: {
      // type: Function,
      required: true,
      default: null
    },
    // 查询的键名
    searchKey: {
      type: String,
      default: 'name'
    },
    needFocus: {
      type: Boolean,
      default: false
    },
    // 外部的查询参数
    searchParams: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //请求之前的校验
    validFn: {
      type: Function | null,
      default: null
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        console.log('newV', newV)
        this.v = newV
      }
    }
  },
  methods: {
    async remoteMethod(query) {
      if (this.searchFn) {
        if (this.validFn && !this.validFn(query, this.searchParams)) {
          //请求之前校验未通过
          return
        }
        try {
          let list = await this.searchFn({
            [this.searchKey]: query,
            ...this.searchParams
            // pageNum: 1,
            // pageSize: 100
          })

          if (!list || !list.length) {
            this.$message.warning('暂无数据，请重新输入关键字搜索')
          }
          this.list = list
        } catch (err) {
          console.log(err)
        }
      } else {
        console.log('请传入搜索函数 searchFn')
      }
    },
    focusEvent() {
      if (this.needFocus && !this.list?.length) {
        this.remoteMethod('')
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _[this.valueKey] == code) || {}
      if (a) {
        this.$emit('update:value', a[this.labelKey] || '')
      }
      this.$emit('change', a)
    },
    setData(keyName, value) {
      this[keyName] = value
    },
    clear() {
      this.v = ''
      this.$emit('clear')
    }
  }
}
</script>
