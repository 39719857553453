import * as moment from 'moment'

export function formatTime(row, column) {
  if (row[column.property] === null) {
    return ''
  }
  return moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
}

export function isNotEmpty(obj) {
  return obj !== undefined && obj !== null && obj !== '' && obj !== 'null'
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function formatBusinessStatus(row, column) {
  const map = {
    1: '正在处理',
    2: '已撤销',
    3: '驳回',
    4: '办结'
  }
  return map[row[column.property]]
}
// 导出的流Blob,filename 导出的文件名
export function downloadFileBlob(data, filename) {
  if (!data) {
    return
  }
  if (data.data && data.headers) {
    if (data.headers && data.headers['content-disposition']) {
      const disposition = data.headers['content-disposition']

      filename = decodeURIComponent(
        disposition.split(';')[1].split('filename=')[1]
      )
    }
    data = data.data
  }
  let url = window.URL.createObjectURL(new Blob([data]))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', filename)

  document.body.appendChild(link)
  link.click()
}
// 用a链接导出处理方案GET方式
export function downloadALink(url, filename) {
  var a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('download', filename)
  a.setAttribute('id', 'startTelMedicine')
  // 防止反复添加
  if (document.getElementById('startTelMedicine')) {
    document.body.removeChild(document.getElementById('startTelMedicine'))
  }
  document.body.appendChild(a)
  a.click()
}

//节流防抖
let throttleLasttimeObj = {} //节流上次执行集合
export function throttle(fn, delay, flagId = 'normal') {
  const lastTime = throttleLasttimeObj[flagId] || 0
  return function (...args) {
    const nowTime = new Date().getTime()
    const remainTime = delay - (nowTime - lastTime)
    if (remainTime <= 0) {
      fn.apply(this, args)
      throttleLasttimeObj[flagId] = nowTime
    }
  }
}
//节流防抖
let debounceFlags = {} //防抖flag集合
export function debounce(fn, delay, flagId = 'normal') {
  return function (...args) {
    if (debounceFlags[flagId]) clearTimeout(debounceFlags[flagId])
    debounceFlags[flagId] = setTimeout(function () {
      fn.apply(this, args)
    }, delay)
  }
}

//深拷贝
export function deepClone(obj) {
  let result = typeof obj?.splice === 'function' ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        result[key] = deepClone(obj[key]) //如果对象的属性值为object的时候，递归调用deepClone,即在吧某个值对象复制一份到新的对象的对应值中。
      } else {
        result[key] = obj[key] //如果对象的属性值不为object的时候，直接复制参数对象的每一个键值到新的对象对应的键值对中。
      }
    }
    return result
  }
  return obj
}

//使一级列表数据形成多级树结构数据
export function transListToTreeData(list, options = {}) {
  if (!list) return []
  list = JSON.parse(JSON.stringify(list))
  let key = options.key || 'id'
  let parentKey = options.parentKey || 'pid'
  let childKey = options.childKey || 'children'
  let r = []
  let tmpMap = []
  for (let j = 0; j < list.length; j++) {
    tmpMap[list[j][key]] = list[j]
  }
  for (let i = 0; i < list.length; i++) {
    if (tmpMap[list[i][parentKey]] && list[i][key] != list[i][parentKey]) {
      if (!tmpMap[list[i][parentKey]][childKey])
        tmpMap[list[i][parentKey]][childKey] = []
      tmpMap[list[i][parentKey]][childKey].push(list[i])
    } else {
      r.push(list[i])
    }
  }
  return r
}

//使多级树结构数据形成一级列表数据
export function transTreeToList(
  treeList = [],
  arr = [],
  options = {},
  parentId = ''
) {
  var childKey = options.childKey || 'children'
  for (let item of treeList) {
    var newItem = { ...item }
    if (newItem[childKey]) {
      delete newItem[childKey]
    }
    if (parentId) {
      arr.push({ ...newItem, parentId })
    } else {
      arr.push(newItem)
    }
    if (item[childKey]?.length)
      transTreeToList(item[childKey], arr, options, newItem.id)
  }
  return arr
}

export function openDownload(link, name) {
  if (!link) return

  if (link.indexOf(process.env.VUE_APP_IMAGE_URL) === -1) {
    link = process.env.VUE_APP_IMAGE_URL + link
  }

  if (!name) {
    let arr = link.split('/')
    name = arr[arr.length - 1]
  }
  const x = new XMLHttpRequest()
  x.open('GET', link, true)
  x.responseType = 'blob'
  x.onload = function () {
    if (window.navigator.msSaveOrOpenBlob) {
      // ie
      navigator.msSaveBlob(x.response, name)
    } else {
      const url = window.URL.createObjectURL(x.response)
      const a = document.createElement('a')
      a.href = url
      a.download = name || ''
      a.click()
    }
  }
  x.send()
}
export function dateFormat(time, fmt = 'YYYY-mm-dd HH:MM:SS') {
  if (!time) return '-'

  let date = ''

  if (typeof time === 'number' && time.toString().length === 10) {
    time = time * 1000
  }
  //为了兼容IE, IE new Date() 不支持含有'-', 另外其他时区的格式比中国标准时间少8个小时需要加回来。
  if (
    typeof time === 'string' &&
    (!!window.ActiveXObject || 'ActiveXObject' in window)
  ) {
    if (time.includes('T')) {
      time = new Date(
        new Date(
          time.split('.000')[0].replace(/T/g, ' ').replace(/-/g, '/')
        ).getTime() +
          8 * 60 * 60 * 1000
      )
    } else {
      time = time.replace(/-/g, '/')
    }
  }

  date = new Date(time)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }

  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)

    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
}

//告诉父级页面要跳转到哪
export function parentIframeJump(url) {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = url
  } else {
    window.parent.postMessage({ type: 'jump', url }, '*')
  }
}

//告诉父级页面要关闭tab标签页
export function parentIframeTabClose(url) {
  window.parent.postMessage({ type: 'tabClose', url }, '*')
}

//告诉父级页面要刷新 通知消息 的数据
export function refreshData(data) {
  window.parent.postMessage({ type: 'refreshData', data }, '*')
}

//告诉父级页面要打开新增门店
export function openAddShop() {
  console.log('告诉父级页面要打开新增门店')
  window.parent.postMessage({ type: 'openAddShop' }, '*')
}
//告诉父级页面要打开新增报障人
export function openAddFaultUser() {
  console.log('告诉父级页面要打开新增报障人')
  window.parent.postMessage({ type: 'openAddFaultUser' }, '*')
}
//告诉父级页面要打开新增报障人
export function openUseTemplate() {
  console.log('告诉父级页面要打开使用模板')
  window.parent.postMessage({ type: 'openUseTemplate' }, '*')
}

//接受父页面传递的数据
// @params{data} 父级页面传递的数据
// @params{cb} 回调函数
export function getParentData(data, cb) {
  if (cb) {
    cb(data)
  }
}

// 文件单位转换
export function formatBytes(a, b) {
  if (0 == a) return '0 B'
  var c = 1024,
    d = b || 2,
    e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}
// 输入框汉字和空格 替换为''
export function replaceStr1(value) {
  if (!value) return
  return value.replace(/[\u4e00-\u9fa5|\s]/g, '')
}
// 输入框空格替换为''
export function replaceStr2(value) {
  return value.replace(/\s/g, '')
}

export function wait(timer = 0) {
  return new Promise(res => {
    setTimeout(res, timer)
  })
}

// 获取近i月的时间段
export const getLastMonth = (i, now = new Date()) => {
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  let dateObj = {}
  dateObj.endTime = year + '-' + month + '-' + day
  let nowMonthDay = new Date(year, month, 0).getDate() //当前月的总天数
  if (i == 12) {
    //如果是12月，年数往前推一年<br>
    dateObj.startTime = year - 1 + '-' + month + '-' + day
  } else if (month - i <= 0) {
    // 如果前推i月小于0，年数往前推一年<br>
    dateObj.startTime = year - 1 + '-' + (12 - (i - month)) + '-' + day
  } else {
    let endTimeMonthDay = new Date(year, parseInt(month) - i, 0).getDate()
    if (endTimeMonthDay < day) {
      // i个月前所在月的总天数小于现在的天日期
      if (day < nowMonthDay) {
        // 当前天日期小于当前月总天数
        dateObj.startTime =
          year +
          '-' +
          (month - i) +
          '-' +
          (endTimeMonthDay - (nowMonthDay - day))
      } else {
        dateObj.startTime = year + '-' + (month - i) + '-' + endTimeMonthDay
      }
    } else {
      dateObj.startTime = year + '-' + (month - i) + '-' + day
    }
  }

  return {
    startTime: dateFormat(dateObj.startTime, 'YYYY-mm-dd'),
    endTime: dateFormat(dateObj.endTime, 'YYYY-mm-dd')
  }
}

export function getUuid(len = 8, radix = 16) {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  let uuid = [],
    i
  radix = radix || chars.length
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    let r
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
