import * as common from './common'
import * as apply from './apply'
import * as u8 from './u8'
import * as flowable from './flowable'
import * as workOrder from './workOrder'
import * as bom from './bom'
import * as workOrderStatistics from './workOrderStatistics'

export default {
  common,
  apply,
  u8,
  flowable,
  workOrder,
  bom,
  workOrderStatistics
}
