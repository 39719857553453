//数据字典

//申请状态
export const auditStatus = [
  {
    label: '审批中',
    value: 0
  },
  {
    label: '已通过',
    value: 1
  },
  {
    label: '已驳回',
    value: 2
  },
  {
    label: '已撤销',
    value: 3
  },
  {
    label: '作废中',
    value: 4
  },
  {
    label: '已作废',
    value: 5
  }
]
//审核状态
export const auditStatus2 = [
  {
    label: '审批中',
    value: 1
  },
  {
    label: '已撤销',
    value: 2
  },
  {
    label: '已驳回',
    value: 3
  },
  {
    label: '已通过',
    value: 4
  },
  {
    label: '已退回',
    value: 5
  },
  {
    label: '系统异常',
    value: 10
  }
]
//申请类型
export const applyType = [
  {
    label: '故障返修申请',
    value: 0
  },
  {
    label: '备机申请',
    value: 1
  },
  {
    label: '备机申请作废',
    value: 11
  },
  {
    label: '配件申请',
    value: 2
  },
  {
    label: '配件申请作废',
    value: 21
  },
  {
    label: '服务配件归还申请',
    value: 3
  },
  {
    label: '服务收费申请',
    value: 4
  },
  {
    label: '设备形态转换申请',
    value: 5
  },
  {
    label: '其他入库申请',
    value: 6
  },
  {
    label: '领料申请(销库)',
    value: 7
  },
  {
    label: '发票申请',
    value: 8
  }
]

//销售类型
export const sellType = [
  {
    label: '成品销售',
    value: 1
  },
  {
    label: '配件销售',
    value: 2
  },
  {
    label: '产品预测',
    value: 3
  },
  {
    label: '配件预测',
    value: 4
  }
]

// 发票票种
export const invoiceType = [
  {
    label: '普票',
    value: 1
  },
  {
    label: '专票',
    value: 2
  },
  {
    label: '收据',
    value: 3
  }
]

//业务类型
export const businessType = [
  {
    label: '普通销售',
    value: 0
  },
  {
    label: '分期收款',
    value: 1
  }
]

//保修方式
export const maintenanceType = [
  {
    label: '上门1个月(配件)',
    value: 12
  },
  {
    label: '上门3个月(配件)',
    value: 13
  },
  {
    label: '上门6个月(配件)',
    value: 14
  },
  {
    label: '上门1年',
    value: 0
  },
  {
    label: '上门2年',
    value: 1
  },
  {
    label: '上门3年',
    value: 2
  },
  {
    label: '上门3.5年',
    value: 11
  },
  {
    label: '上门4年',
    value: 3
  },
  {
    label: '上门5年',
    value: 4
  },
  {
    label: '送修1个月(配件)',
    value: 15
  },
  {
    label: '送修3个月(配件)',
    value: 16
  },
  {
    label: '送修6个月(配件)',
    value: 17
  },
  {
    label: '送修1年',
    value: 5
  },
  {
    label: '送修2年',
    value: 6
  },
  {
    label: '送修3年',
    value: 7
  },
  {
    label: '送修4年',
    value: 8
  },
  {
    label: '送修5年',
    value: 9
  },
  {
    label: '其他',
    value: 10
  }
]

//付款方式
export const payWay = [
  {
    label: '款到发货',
    value: 1
  },
  {
    label: '货到付款',
    value: 2
  },
  {
    label: '分期付款',
    value: 3
  }
]
// 处理方式
export const workSolveWay = [
  { label: '上门', value: 1 },
  { label: '寄修', value: 2 },
  { label: '外协', value: 3 },
  { label: '远程（有配件）', value: 4 },
  { label: '远程（无配件）', value: 5 }
]
// 配件更换方式
export const replaceMode = [
  { label: '无更换', value: 0 },
  { label: '整机更换', value: 1 },
  { label: '配件更换', value: 2 },
]
