//选择客户
<template>
  <el-select
    v-model="v"
    @change="changeEvent"
    style="width: 100%"
    filterable
    @visible-change="getList"
    :popper-class="popperClass"
  >
    <el-option
      v-for="a in list"
      :key="a.ccusCode"
      :value="a.ccusCode"
      :label="a.ccusName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectCustomer',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      v: '',
      list: [],
      popperClass: ''
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    },
    list: {
      deep: true,
      handler(newV, oldV) {
        if (this.code && !this.name) {
          let a = newV.find(_ => _.ccusCode === this.code) || {}
          this.$emit('update:name', a.ccusName)
        }
      }
    }
  },
  created() {
    this.popperClass = 'selectCustomerPopper' + this.$utils.getUuid()
    this.getList(true)
  },
  methods: {
    async getList(visible) {
      if (!visible) return
      const loading = this.$loading({
        lock: true,
        target: '.' + this.popperClass,
        spinner: 'el-icon-loading',
        background: 'white'
      })
      try {
        const list = await this.$api.u8.getCustomerList()
        this.list = list || []
      } catch (err) {
        console.log(err)
      } finally {
        loading.close()
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.ccusCode === code) || {}
      this.$emit('update:name', a.ccusName)
      this.$emit('update:code', code)
      this.$emit('change', a)
    }
  }
}
</script>
