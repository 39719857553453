import Vue from 'vue'
import axios from 'axios'

import { Notification, MessageBox, Message } from 'element-ui'

Vue.prototype.$axios = axios

let preUrl = process.env.NODE_ENV === 'development' ? '/admin' : '/admin'
axios.defaults.baseURL = window.location.origin + preUrl

axios.defaults.timeout = 1000 * 60 * 10

axios.defaults.withCredentials = true // 让ajax携带cookie
axios.interceptors.request.use(
  // 每次请求都自动携带Cookie
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  resp => {
    // console.log('response', 'data' in resp, resp.data)
    if (Array.prototype.toString.call(resp.data) === '[object Blob]') {
      // 文件下载
      if (
        resp.headers &&
        resp.headers['content-disposition'] &&
        resp.headers['content-disposition'].indexOf('filename') !== -1
      ) {
        return resp
      }
      return 'data' in resp ? resp.data || [] : resp
    }

    if (resp.data.code == 0) {
      return resp.data.data
    } else if (resp.config.options?.backIsArray && resp.data instanceof Array) {
      return resp.data
    }
    Message.error(resp.data.msg || '服务器出了点小错误')
    return Promise.reject(resp.data.msg)
  },
  // 拦截异常的响应
  err => {
    if (err?.message) {
      Message.error(err.message)
    } else {
      switch (err.response.status) {
        case 401:
          MessageBox.alert('登陆已过期，请关闭当前窗口重新进入')
          break
        case 403:
          Message.warning('抱歉，您无权访问！')
          break
        case 500:
          Notification.error({
            title: '提醒',
            message: '服务器出了点小错误'
          })
          break
        case 404:
          Notification({
            title: '提醒',
            message: '未找到，检查参数',
            type: 'warning'
          })
          break
        default:
          return Promise.reject(err)
      }
    }

    return Promise.reject(err)
  }
)

export default axios
