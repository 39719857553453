//选择出入库类别
<template>
  <el-select v-model="v" @change="changeEvent" style="width: 100%" filterable>
    <el-option
      v-for="a in list"
      :key="a.crdCode"
      :value="a.crdCode"
      :label="a.crdName"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectWarehouseType',
  props: {
    name: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0 //0出库 1入库
    }
  },
  data() {
    return {
      v: '',
      list: []
    }
  },
  watch: {
    code: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        this.v = newV
      }
    },
    list: {
      deep: true,
      handler(newV, oldV) {
        if (this.code && !this.name) {
          let a = newV.find(_ => _.crdCode === this.code) || {}
          this.$emit('update:name', a.crdName)
        }
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const list = await this.$store.dispatch('getRdStyle', this.type)
        this.list = list
      } catch (err) {
        console.log(err)
      }
    },
    changeEvent(code) {
      let a = this.list.find(_ => _.crdCode === code) || {}
      this.$emit('update:name', a.crdName)
      this.$emit('update:code', code)
      this.$emit('change', a)
    }
  }
}
</script>
