import axios from 'axios'
import request from '@/api/request.js'
import * as utils from '@/utils/index'

//上传文件
export const uploadFile = async files => {
  const bool = Array.isArray(files)
  let promises = bool ? files : [files]

  promises = promises.map(file => {
    if (typeof file === 'string') return Promise.resolve(file)
    const data = new FormData()
    console.log('file', file)
    data.append('file', file)
    return axios.post('fdsFile/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  })
  const res = await Promise.all(promises)
  console.log('res', res)
  return Array.isArray(res) && res.length === 1 ? res[0] : res
}

// 上传文件2 单文件，
export const uploadFile2 = async data => {
  return axios.post('fdsFile/upload', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
// 上传文件
export const uploadFile3 = async data => {
  return axios.post('/fs/fittingData/import', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 老项目文件上传
export const oldUploadFile = async file => {
  const data = new FormData()
  data.append('file', file)
  return axios.post('/document/info/uploadFile', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 下载、导出文件
export function downloadExport(params, filename = '文件') {
  return new Promise((resolve, reject) => {
    request
      .get('/file/downloadExport', {
        params,
        responseType: 'blob'
      })
      .then(data => {
        utils.downloadFileBlob(data.data, filename)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//菜单
export function getMenu(params) {
  return request({
    url: `index/new/menu`,
    method: 'get',
    params
  })
}

//工单申请人列表
export function getUserList(params) {
  return request({
    url: `power/user/userList`,
    method: 'get',
    params,
    options: { backIsArray: true }
  })
}

//省
export function getProvince(params) {
  return request({
    url: `region/province/list`,
    method: 'post',
    params
  })
}

//市
export function getCity(params) {
  return request({
    url: `region/city/all`,
    method: 'post',
    params,
    options: { backIsArray: true }
  })
}

//区县
export function getArea(params) {
  return request({
    url: `region/area/all`,
    method: 'post',
    params,
    options: { backIsArray: true }
  })
}

//用户信息
export function getUserInfo(params) {
	return request({
	  url: `power/user/info`,
	  method: 'get',
	  params,
	  options: { backIsArray: true }
	})
}
