import Vue from 'vue'
import Page from './Page.vue'
import Ellipsis from './Ellipsis.vue'
import Tip from './Tip.vue'
import WDialog from './WDialog.vue'
import UploadFile from './UploadFile.vue'
import FileList from './FileList.vue'
import Pagination from './Pagination.vue'
import SelectProposer from './SelectProposer.vue'
import SelectUserList from './SelectUserList.vue'
import SelectDepartment from './SelectDepartment.vue'
import SelectCustomer from './SelectCustomer.vue'
import SelectWarehouse from './SelectWarehouse.vue'
import SelectGoodsCode from './SelectGoodsCode.vue'
import SelectGoodsName from './SelectGoodsName.vue'
import SelectGoodsLocation from './SelectGoodsLocation.vue'
import SelectWarehouseType from './SelectWarehouseType.vue'
import CuttingUpload from './CuttingUpload.vue'
import SelectGoods from './SelectGoods.vue'
import SelectSearch from './SelectSearch.vue'
import SelectSn from './SelectSn.vue'

const CommonComponents = [
  Page,
  Ellipsis,
  Tip,
  WDialog,
  UploadFile,
  FileList,
  Pagination,
  SelectProposer,
  SelectUserList,
  SelectDepartment,
  SelectCustomer,
  SelectWarehouse,
  SelectGoodsCode,
  SelectGoodsName,
  SelectGoodsLocation,
  SelectWarehouseType,
  CuttingUpload,
  SelectGoods,
  SelectSearch,
  SelectSn
]

export default CommonComponents.map(Component => {
  return () => {
    if (!Component.name) {
      console.error('组件未设置name属性，无法注册为全局组件', Component)
    } else {
      return Vue.component(Component.name, Component)
    }
  }
})
