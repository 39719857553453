//上传多类型文件
<template>
  <div v-loading="loading">
    <el-upload
      class="upload-file"
      ref="upload"
      action="null"
      :on-remove="handleRemove"
      :multiple="multiple"
      :limit="limit"
      :http-request="uploadSectionFile"
      :before-upload="beforeAvatarUpload"
      :accept="accept"
      :show-file-list="showFileList"
      :file-list="historyFileList"
      :on-exceed="handleExceed"
    >
      <el-button
        slot="trigger"
        size="small"
        type="primary"
        icon="el-icon-upload2"
      >
        {{ btnText }}
      </el-button>
      <!-- <el-button
        v-if="preAppendixAddr && list.length === 0"
        size="small"
        type="primary"
        icon="el-icon-download"
        style="margin-left: 20px"
        @click="downloadFile"
      >
        下载附件
      </el-button> -->
      <!-- <div slot="file" slot-scope="{ file }">
        <li :class="`el-upload-list__item`">
          <a :href="file.url" target="_blank" class="el-upload-list__item-name">
            <i class="el-icon-document"></i>
            {{ file.name }}
          </a>
          <label class="el-upload-list__item-status-label" v-if="file.url">
            <i class="el-icon-upload-success el-icon-circle-check"></i>
          </label>
          <i @click.stop="handleRemove(file)" class="el-icon-close"></i>
        </li>
      </div> -->
    </el-upload>
    <div class="tip" v-if="showAccept">
      <p v-if="accept">支持扩展名：{{ accept.split(',').join(' ') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFile',
  model: {
    prop: 'value',
    event: 'valChange'
  },
  props: {
    //编辑时，前附件地址
    preAppendixAddr: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 1
    },
    limitFileNameLength: {
      type: Number,
      default: 60
    },
    maxSize: {
      type: Number,
      default: 20
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default:
        '.rar,.zip,.doc,.docx,.pdf,.jpg,.jpeg,.png,.xls,.ppt,.pptx,.xlsx,.avi,.mov,.mp4,.wmv,.mkv,.asf,.flv,.mpeg,.m4v,.rmvb,.3gp,.vob'
    },
    needRequest: {
      type: Boolean,
      default: true
    },
    showAccept: {
      type: Boolean,
      default: true
    },
    btnText: {
      type: String,
      default: '上传附件'
    },
    // 使用老项目的上传接口
    oldUpload: {
      type: Boolean,
      default: false
    },
    //是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    preAppendixAddr: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV && newV != oldV) {
          let fileList = newV.split(',').map((a, index) => {
            let url = a.split('?fileName=')[0]
            let name = a.split('?fileName=')[1] || url
            return { url, name }
          })
          this.list = fileList
          this.historyFileList = fileList
          this.$emit('valChange', fileList)
        }
      }
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      historyFileList: []
    }
  },
  methods: {
    valueInit(list) {
      this.list = list || []
      if (!list?.length) {
        this.$refs.upload?.clearFiles()
      }
    },
    handleRemove(file) {
      const index = this.list.findIndex(_ => _.uid === file.uid)
      if (index != -1) {
        this.list.splice(index, 1)
        this.$emit('valChange', this.list)
      }
    },
    handleExceed() {
      this.$message.warning(`文件数量不能超过${this.limit}个`)
    },
    beforeAvatarUpload(file) {
      const { limitFileNameLength, maxSize } = this
      const isLt5M = file.size / 1024 / 1024 <= maxSize

      if (limitFileNameLength && file.name?.length > limitFileNameLength) {
        this.$message.warning(`上传文件名长度不能超过${limitFileNameLength}`)
        return false
      }
      if (limitFileNameLength && file.name?.includes(',')) {
        this.$message.warning(`上传文件名不能包含英文豆号,`)
        return false
      }
      if (!isLt5M) {
        this.$message.warning(`上传文件大小不得超过${maxSize}MB，请重新上传`)
        return false
      }
      return true
    },
    async uploadSectionFile(params) {
      try {
        const { file } = params
        const { uid } = file
        const type = file.type.indexOf('image') !== -1 ? 0 : 1
        if (this.needRequest) {
          this.loading = true

          let url = '',
            id = ''
          if (this.oldUpload) {
            //  使用老项目的上传接口
            let { url: oldUrl, id: oldId } =
              await this.$api.common.oldUploadFile(file)
            url = oldUrl
            id = oldId
          } else {
            url = await this.$api.common.uploadFile(file)
          }
          url = url.replace('https', 'http')
          this.list.push({ name: file.name, url, type, uid, id })
        } else {
          let url =
            window.webkitURL?.createObjectURL(file) ||
            window.URL?.createObjectURL(file) ||
            window.createObjectURL(file)

          this.list.push({ name: file.name, url, type, uid, file })
        }

        this.$emit('valChange', this.list)
      } catch (err) {
        // 失败删除回显列
        const idx = this.$refs.upload?.uploadFiles?.findIndex(
          item => item.uid === file.file.uid
        )
        idx && this.$refs.upload?.uploadFiles?.splice(idx, 1)
      } finally {
        this.loading = false
      }
    },
    openUrl(file) {
      const _url = file.url.replace('https', 'http')
      window.open(_url)
    },
    downloadFile() {
      let url = (name = this.preAppendixAddr)
      if (this.preAppendixAddr.includes('?fileName=')) {
        url = this.preAppendixAddr.split('?fileName=')[0]
        name = this.preAppendixAddr.split('?fileName=')[1]
      }

      this.$utils.openDownload(url, name)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .upload-file {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1;
  .el-upload-list__item {
    margin-top: 0;
    margin-left: 5px;
  }
}
.tip {
  margin-top: 10px;
  p {
    color: #999;
    font-size: 12px;
    line-height: 24px;
  }
}
</style>
