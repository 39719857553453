import request from '@/api/request.js'

//分页查询Bom信息
export function getBomPage(params) {
  return request({
    url: `bom/page`,
    method: 'get',
    params
  })
}

//查询Bom明细
export function getBomDetail(params) {
  return request({
    url: `bom/details`,
    method: 'get',
    params
  })
}

// 改变可见状态
export function setBomEnable(id) {
  return request({
    url: `bom/detail/${id}`,
    method: 'put'
  })
}

// 处理完成
export function setBomFinish(id) {
  return request({
    url: `bom/${id}`,
    method: 'put'
  })
}

// bom同步
export function bomSync(params) {
  return request({
    url: `bom/sync`,
    method: 'post',
    params
  })
}
