//是否是手机号
export function isPhone(phone) {
  return /^1[\d | *]{10}$/.test(phone)
}

//是否是座机
export function isTel(tel) {
  return (
    /^0\d{2,3}-?[\d | *]{7,8}$/.test(tel) ||
    /^[\d | *]{7,8}$/.test(tel) ||
    /^1[\d | *]{10}$/.test(tel)
  )
}

//是否是身份证
export function isIDCard(idCard) {
  return (
    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
      idCard
    ) || /^[\d | *]{18}$/.test(idCard)
  )
}

//只能输入数字
export function fmtNumber(value) {
  return value.replace(/[^\d]/g, '')
}

//格式化金额，默认最多两位小数
export function fmtAmt(value, n = 2) {
  if (!value) value = ''
  if (value != '' && value.substr(0, 1) == '.') {
    value = ''
  }
  value = value.replace(/^0*(0\.|[1-9])/, '$1') //解决 粘贴不生效
  value = value.replace(/[^\d.]/g, '') //清除“数字”和“.”以外的字符
  value = value.replace(/\.{2,}/g, '.') //只保留第一个. 清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  var reg = new RegExp(`^(\\-)*(\\d+)\\.(\\d{0,${n}}).*$`) //只能输入n个小数
  value = value.replace(reg, '$1$2.$3')
  if (value.indexOf('.') < 0 && value != '') {
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (value.substr(0, 1) == '0' && value.length == 2) {
      value = value.substr(1, value.length)
    }
  }
  return value
}

//数字格式化
export function dividedInto(value, unit, toFixed = true, empty = ' ') {
  if (value === '/' || value === '-') return value
  if (value === null || value === undefined) return empty
  if (!value) return 0
  if (!+value || typeof +value !== 'number') return 0

  const units = ['万', '亿', '万亿']

  if (unit) {
    const unitIndex = units.findIndex(_ => _ === unit)

    if (unitIndex !== -1) {
      let r = 1
      for (let i = 0; i < unitIndex + 1; i++) {
        r = r * 10000
      }
      const n = toFixed ? +(value / r).toFixed(2) : value / r
      return n < 1 ? n : n.toLocaleString()
    }
  }
  const ar = value.toString().split('.')
  return (+ar[0]).toLocaleString() + (ar[1] ? '.' + ar[1] : '')
}

//日期格式化
export const dateFormat = (time, fmt = 'YYYY-mm-dd HH:MM:SS') => {
  if (!time) return '-'

  let date = ''

  if (typeof time === 'number' && time.toString().length === 10) {
    time = time * 1000
  }
  //为了兼容IE, IE new Date() 不支持含有'-', 另外其他时区的格式比中国标准时间少8个小时需要加回来。
  if (
    typeof time === 'string' &&
    (!!window.ActiveXObject || 'ActiveXObject' in window)
  ) {
    if (time.includes('T')) {
      time = new Date(
        new Date(
          time.split('.000')[0].replace(/T/g, ' ').replace(/-/g, '/')
        ).getTime() +
          8 * 60 * 60 * 1000
      )
    } else {
      time = time.replace(/-/g, '/')
    }
  }

  date = new Date(time)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }

  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)

    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
      )
    }
  }
  return fmt
}
