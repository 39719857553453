//展示多个文件
<template>
  <div>
    <span v-for="(item, index) in fileList" :key="index">
      <a
        href="javascript:;"
        class="link"
        @click="downloadFile(item)"
        :title="`点击下载${item.name}`"
      >
        {{ item.name }}
      </a>
      <span v-if="index < fileList.length - 1">，</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'FileList',
  props: {
    attachmentUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  watch: {
    attachmentUrl: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV && newV != oldV) {
          let fileList = newV.split(',').map((a, index) => {
            let url = a.split('?fileName=')[0]
            let name = a.split('?fileName=')[1] || url
            return { url, name }
          })
          this.fileList = fileList
        }
      }
    }
  },
  methods: {
    downloadFile(item) {
      const { url, name } = item
      this.$utils.openDownload(url, name)
    }
  }
}
</script>

<style lang="less" scoped>
.link {
  color: #009688;
  cursor: pointer;
}
</style>
