import { dividedInto, dateFormat } from './format.js'
export default Vue => {
  Vue.filter('date', function (value, fmt = 'YYYY-mm-dd HH:MM:SS') {
    return dateFormat(value, fmt)
  })

  Vue.filter('dividedInto', function (value, unit, toFixed, empty) {
    return dividedInto(value, unit, toFixed, empty)
  })
}
