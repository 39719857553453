// import checkAuth from './checkAuth'
import store from '../store'
export default Vue => {
  Vue.directive('auth', {
    inserted: (el, bind, vnode) => {
      let permission = false
      const authArr = bind.value?.split(',')
      const { currentRoute, menuList } = store.state
      if (currentRoute.path && authArr.length >= 1) {
        const curPath = '/#' + currentRoute.path
        const parentId = menuList.find(a => a.url === curPath)?.id
        if (parentId) {
          let item = menuList
            .filter(a => a.parentId === parentId)
            .find(b => authArr.includes(b.permission))
          if (item) {
            permission = true
          }
        }
      }

      // 本地 默认给权限
      if (process.env.NODE_ENV == 'development') permission = true

      if (!permission) el.parentNode.removeChild(el)
    }
  })
}
