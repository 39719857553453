<template>
  <!-- 图片上传 -->
  <div class="wrap">
    <el-button
      type="primary"
      @click="$refs.uploads.click()"
      :disabled="disabled"
      v-if="!multiple ? !fileList.length : fileList.length < limit"
      size="small"
    >
      选择图片
    </el-button>
    <div class="img-list">
      <div class="img-wrap" v-for="(item, index) in fileList" :key="index">
        <img :src="interceptImg(item.url)" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlerPreview(item.url)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- <span @click.stop="handleDownload(item)">
            <i class="el-icon-download"></i>
          </span> -->
          <span @click.stop="handleRemove(index)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </div>
    <div v-if="showTips" class="el-upload__tip" slot="tip">
      <span>·) 只能上传.png、.jpg、.jpeg格式的文件</span>
      <span v-if="fileSize">
        ·) 图片大小不超过{{ $utils.formatBytes(fileSize) }}
      </span>
      <span v-if="boxWidth && boxHeight">
        ·) 图片像素尺寸（px）应为{{ boxWidth }}*{{ boxHeight }}
      </span>
      <span v-for="(tip, index) in tips" :key="index">·) {{ tip }}</span>
    </div>

    <input
      type="file"
      :multiple="multiple"
      id="uploads"
      ref="uploads"
      style="position: absolute; clip: rect(0 0 0 0)"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      @change="selectImg($event)"
    />
    <!-- v-if="!visible" -->

    <el-dialog
      append-to-body
      title="裁切图片"
      :visible.sync="visible"
      v-if="visible"
      :destroy-on-close="true"
      close-on-click-modal
      width="1280px"
      top="10vh"
      center
    >
      <cropper-image
        :base64="base64"
        :visible.sync="visible"
        :sizeChange="sizeChange"
        :boxWidth="boxWidth"
        :boxHeight="boxHeight"
        @cropSuccess="cropSuccess"
        ref="child"
      ></cropper-image>
    </el-dialog>
  </div>
</template>

<script>
import CropperImage from './CropperImage'
export default {
  name: 'CuttingUpload',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    sizeChange: {
      default: true
    },
    boxWidth: {
      type: Number
    },
    // 限制图片上传数量
    limit: {
      type: Number,
      default: 1
    },
    // 图片超大时是否开启 图片裁切，新项目上传接口可以，未兼容老项目上传接口==没时间
    cutting: {
      type: Boolean,
      default: true
    },
    boxHeight: {
      type: Number
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // 文件大小，B
    fileSize: {
      type: Number,
      default: 20 * 1024 * 1024
    },
    value: {}, // 多图时传 数组
    tips: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showTips: {
      type: Boolean,
      default: true
    },
    // 使用老项目的上传接口
    oldUpload: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CropperImage
  },

  data() {
    return {
      fileList: [],
      visible: false,
      base64: ''
    }
  },
  computed: {
    srcList() {
      return this.fileList.map(_ => this.interceptImg(_.url))
    },
    rate() {
      return `${this.boxWidth}:${this.boxHeight}`
    }
  },
  created() {},
  watch: {
    value: {
      handler(val) {
        console.log('watchVal', val)
        if (Array.isArray(val)) {
          this.fileList = val.map(_ => {
            return { url: _, name: _ + Date.now() + '.png' }
          })
        } else {
          if (val) this.fileList = [{ url: val, name: Date.now() + '.png' }]
        }
      },
      immediate: true
    }
  },
  methods: {
    //选择图片
    async selectImg(e) {
      let file = e.target.files[0]

      if (
        this.multiple &&
        this.limit &&
        this.fileList &&
        this.fileList.length >= this.limit
      ) {
        return this.$message.warning(`最多上传${this.limit}张图片`)
      }

      if (this.fileSize) {
        const maxSize = this.fileSize
        if (file.size > maxSize && !this.cutting) {
          return this.$message.warning(
            `图片不能大于${this.$utils.formatBytes(maxSize)}`
          )
        }
      }

      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: 'error'
        })
        return false
      }

      // if (!this.cutting) {

      //   return
      // }
      // 压缩上传没空兼容老项目接口上传
      if (file.size > this.fileSize && this.cutting && !this.oldUpload) {
        //图片超大并且 开启图片裁切时
        this.$message.info(
          `图片大于${this.$utils.formatBytes(this.fileSize)}，请裁切图片`
        )
        let timer = setTimeout(() => {
          //转化为blob
          let reader = new FileReader()
          reader.onload = e => {
            console.dir(e)
            let data
            if (typeof e.target.result === 'object') {
              data = window.URL.createObjectURL(new Blob([e.target.result]))
            } else {
              data = e.target.result
            }
            // console.log('data', data)
            this.base64 = data
            this.visible = true
          }
          //转化为base64
          this.name = file.name

          reader.readAsDataURL(file)
          clearTimeout(timer)
          return
        }, 1800)
      } else {
        // 未超大图片
        try {
          this.loading = true
          const fd = new FormData()
          fd.append('file', file, file.name)
          let { uid } = file
          let res = '',
            id = ''
          console.log('this.oldUpload', this.oldUpload)
          if (this.oldUpload) {
            //  使用老项目的上传接口
            let { url: oldUrl, id: oldId } =
              await this.$api.common.oldUploadFile(file)
            console.log('rul', oldUrl, oldId)
            res = oldUrl
            id = oldId
          } else {
            res = await this.$api.common.uploadFile(file)
            id = uid
          }

          if (Array.isArray(this.value)) {
            this.fileList.push({ url: res, name: file.name, id })

            const s = this.fileList.map(_ => _.url)
            this.$emit('update:value', s)
            this.$emit('change', this.fileList)
          } else {
            this.fileList = [{ url: res, name: file.name, id }]
            console.log(2222, this.fileList[0], res)
            this.$emit('update:value', res)
            this.$emit('change', res)
            this.$emit('selectImg', this.fileList)
          }
          // console.log('fileList', this.fileList)
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
    handlerPreview(url) {
      this.$viewerApi({
        images: this.srcList
      })
    },
    // cutDown({ file, dataURL, name }) {
    //   this.fileList.push({ url: dataURL, name })
    //   console.log(file)
    // },

    handleDownload({ url, name }) {
      console.log(1111, url, name)
      // this.$utils.openDownload(url, name || url)
    },
    handleRemove(index) {
      this.fileList.splice(index, 1)
      console.log(this.fileList, index)
      this.$refs.uploads.value = ''
      if (Array.isArray(this.value)) {
        const s = this.fileList.map(_ => _.url)
        this.$emit('update:value', s)
        this.$emit('change', s)
      } else {
        this.$emit('update:value', '')
        this.$emit('change', '')
      }
    },

    // 未兼容老项目上传接口
    async cropSuccess(blob) {
      try {
        this.loading = true
        let file = new FormData()
        //当一个 Blob 或 File 被作为第二个参数的时候， Blob 对象的默认文件名是 'blob'。 File 对象的默认文件名是该文件的名称
        // 第三个参数需要带后缀，否则后端接口会报错
        file.append('file', blob, this.name)

        const res = await this.$api.common.uploadFile2(file)

        if (Array.isArray(this.value)) {
          this.fileList.push({ url: res, name: this.name })

          const s = this.fileList.map(_ => _.url)
          this.$emit('update:value', s)
          this.$emit('change', s)
        } else {
          this.fileList = [{ url: res, name: this.name }]

          this.$emit('update:value', res)
          this.$emit('change', res)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
        this.visible = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.img-list {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 10px;
  .img-wrap {
    border: 1px solid #c0ccda;
    border-radius: 6px;
    overflow: hidden;
    margin: 5px;
    // & + .img-wrap {
    // }
  }
  .img-wrap,
  img {
    width: 80px;
    height: 80px;
    line-height: 80px;
    object-fit: cover;
    position: relative;
  }
}
.el-upload-list__item-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  span {
    & + span {
      margin-left: 15px;
    }
    cursor: pointer;
  }
}

.el-upload__tip {
  display: flex;
  flex-direction: column;
  span {
    color: #ccc;
  }
}
</style>
