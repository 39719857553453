import request from '@/api/request.js'

// 查询用户
export function listUsers(param) {
  return request({
    url: 'flowable/user/list',
    method: 'get',
    params: param
  })
}
// 查询分组
export function listGroups(param) {
  return request({
    url: 'flowable/group/list',
    method: 'get',
    params: param
  })
}
// 查询分组以及分组下的流程模板
export function listGroupsWithTemplate(param) {
  return request({
    url: 'flowable/group/listWithTemplates',
    method: 'get',
    params: param
  })
}
// 分组更新
export function updateGroup(param, method) {
  return request({
    url: 'flowable/group/edit',
    method: method,
    params: param
  })
}
// 分组删除
export function deleteGroup(param) {
  return request({
    url: 'flowable/group/delete',
    method: 'delete',
    params: param
  })
}
// 分组排序
export function sortGroup(data) {
  return request({
    url: 'flowable/group/sort',
    method: 'put',
    data: data
  })
}
// 模板更新
export function updateTemplate(data, method) {
  return request({
    url: 'flowable/template/edit',
    method: method,
    data: data
  })
}
// 模板移动
export function moveTemplate(param) {
  return request({
    url: 'flowable/template/move',
    method: 'put',
    params: param
  })
}
// 模板停用或者启用
export function changeTemplateStatus(param) {
  return request({
    url: 'flowable/template/change',
    method: 'put',
    params: param
  })
}
// 模板删除
export function deleteTemplate(param) {
  return request({
    url: 'flowable/template/delete',
    method: 'delete',
    params: param
  })
}
// 模板明细
export function templateInfo(templateId) {
  return request({
    url: `flowable/template/${templateId}`,
    method: 'get'
  })
}
// 消息通知方式
export function listNoticeTypes() {
  return request({
    url: 'flowable/listNoticeTypes',
    method: 'get'
  })
}
// 条件分支配置条件
export function getConditionConfig(param) {
  return request({
    url: 'flowable/getConditionConfig',
    method: 'get',
    params: param
  })
}
// 我的申请
export function selfApply(param) {
  return request({
    url: `flowable/selfApply`,
    method: 'get',
    params: param
  })
}
// 我的待办
export function selfTodo(param) {
  return request({
    url: `flowable/selfTodo`,
    method: 'get',
    params: param
  })
}
// 我的已办
export function selfDone(param) {
  return request({
    url: `flowable/selfDone`,
    method: 'get',
    params: param
  })
}
// 抄送我的
export function selfAbout(param) {
  return request({
    url: `flowable/selfAbout`,
    method: 'get',
    params: param
  })
}
// 检查是否存在自选申请人的节点
export function listNeedSelfSelectNodes(param) {
  return request({
    url: `flowable/listNeedSelfSelectNodes`,
    method: 'get',
    params: param
  })
}
// 流程实例
export function instanceInfo(param) {
  return request({
    url: `flowable/instanceInfo`,
    method: 'get',
    params: param
  })
}
// 流转信息
export function listAssignedUsers(param) {
  return request({
    url: `flowable/listAssignedUsers`,
    method: 'get',
    params: param
  })
}
// 流转信息
export function initAssignedUsers(orderType, data) {
  return request({
    url: `flowable/listAssignedUsers/${orderType}`,
    method: 'post',
    data: data
  })
}

// 启动流程
export function start(data) {
  return request({
    url: `flowable/start`,
    method: 'post',
    data
  })
}
// 启动流程
export function restart(data) {
  return request({
    url: `flowable/restart`,
    method: 'post',
    data
  })
}

// 审核通过
export function approved(data) {
  return request({
    url: `flowable/approved`,
    method: 'post',
    data
  })
}
// 审核退回
export function rollback(data) {
  return request({
    url: `flowable/rejected`,
    method: 'post',
    data
  })
}
// 审核驳回
export function rejected(data) {
  return request({
    url: `flowable/rejected`,
    method: 'post',
    data
  })
}
// 撤销申请
export function revoke(processInstanceId, param) {
  return request({
    url: `flowable/revoke/${processInstanceId}`,
    method: 'post',
    params: param
  })
}
// 催办
export function reminders(processInstanceId, param) {
  return request({
    url: `flowable/reminders/${processInstanceId}`,
    method: 'post',
    params: param
  })
}
